const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'
import m from 'mithril'


const link_box = {
    view: vnode => {
        return [
            m('a.link_box',
                {
                    href: vnode.attrs.link || '/here'
                },

                m('img.link_box_image',
                    {
                        src: image_base + vnode.attrs.img + '.jpg',
                        alt: vnode.attrs.alt,
                    }
                ),
                m('.link_box_text',
                    m('.link_box_header_1',
                        // m('img', { src: image_base + vnode.attrs.icon , alt: vnode.attrs.icon_alt}),
                        vnode.attrs.header_1 || 'Default'),
                    m('.link_box_header_2', vnode.attrs.header_2 || 'Default second header here'),
                ),
                // m('.link_box_link',
                //     vnode.attrs.link_text || 'Link'
                // ),
            )
        ]
    }
}


export default link_box


import m from 'mithril'

import { facebook_real } from './icons'
const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'

const follow = {
    view: vnode => {

        return [
            m('.follow_section',
                // m('b.text', 'Follow '),

                m("a", {
                    "href": "https://www.facebook.com/people/Library-of-Short-Stories/100087914495294/",
                    "target": "_blank",
                    "title": "Follow on Facebook",
                },
                    m(facebook_real)
                ),

                m("a.kofi_link", {
                    "href": "https://ko-fi.com/evanclewis",
                    "title": "Support me on Ko-fi",
                },
                m('img.kofi_image', { src: image_base + 'kofi_bg_tag_white.png' }),
                )
            )
        ]
    }
}

export default follow
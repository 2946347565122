import m from 'mithril'

import { facebook_real } from './icons'

const follow = {
    view: vnode => {

        return [
            m('.follow_section',
                // m('b.text', 'Follow '),

                m("a", {
                    "href": "https://www.facebook.com/people/Library-of-Short-Stories/100087914495294/",
                    "target": "_blank",
                    "title": "Follow on Facebook",
                },
                    m(facebook_real)
                )
            )
        ]
    }
}

export default follow